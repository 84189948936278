// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-car-details-js": () => import("./../src/templates/CarDetails.js" /* webpackChunkName: "component---src-templates-car-details-js" */),
  "component---src-templates-jade-details-js": () => import("./../src/templates/JadeDetails.js" /* webpackChunkName: "component---src-templates-jade-details-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-booking-success-js": () => import("./../src/pages/bookingSuccess.js" /* webpackChunkName: "component---src-pages-booking-success-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-process-js": () => import("./../src/pages/paymentProcess.js" /* webpackChunkName: "component---src-pages-payment-process-js" */)
}

